<template>
    <div class="jot pb-2">
        <h1 :class="$mq < 3 ? 'mt-3' : ''" class="pb-2 my-2 capitalized w-fc border-bottom text-kc-blue">Join Our Team</h1>
        <hr class="w-100">
        <h5>We have a range of opportunities available during the Summer months</h5>
        <p><strong>Description</strong></p><p>If you&#8217;re relatively new to kayaking and want to get some experience on a variety of white water difficulties, or have been kayaking for a while but don&#8217;t yet have any coaching proficiency then this is the opportunity for you. You&#8217;ll get involved with the operations of a white water tours business and be able to spend time improving your kayaking skills with our guides.Â </p><p>Our internship model is really simple and aims to give you every chance to get out on the amazing white water around Pucon in exchange for helping out in the shop. Start your day at 09:30 by getting the shop ready for business and preparing for any trips happening that day. If you get all the morning tasks done quickly then you may be able to join one of the trips happening that day, depending on staffing and your general helpfulness around the shop. Typically we&#8217;ll aim to get you on the river at least 2-3 times per week.</p><p>Otherwise there&#8217;s lots to do in and around the shop: fixing kayaks and other equipment, managing the website and social media sites and contributing any particular skills which you may have. In the past we&#8217;ve had people help rebuild our website, design marketing collateral and go around town distributing flyers to get the word out about Kayak Chile. In the evening help put equipment from the day&#8217;s trips away and close up shop. Then go and enjoy Pucon or some of the great scenery in the area.</p>
        <ul><li><strong>Salary:</strong> We don&#8217;t offer a salary for our interns. However, we will pay you 5% to 10% commission on any trips which you sell outside of the shop. So if you meet people, tell them about Kayak Chile and they then try out one of our trips we will give you a percentage of the sale.</li><li><strong>Work hours:</strong> 09:30 to 18:00/19:00 (depending on trips) &#8211; 6 days/week</li><li><strong>Duration:</strong> Typically 1 &#8211; 3 months</li><li><strong>Season:</strong> October &#8211; April</li></ul><p><strong>Other details</strong></p><ul><li><strong>Accommodation:</strong> We can help you find accommodation in Pucon, however you will have to pay rent yourself. Accommodation costs in Pucon are likely to be $300.000 to $400.000 per month for a hostel, or $150.000 to $300.000 per month for a homestay.</li><li><strong>Equipment:</strong> If you don&#8217;t have your own equipment you are welcome to use the shop&#8217;s.<br />Prior kayaking experience: Comfortable on flat water and preferably experience on class 1-2 white water.</li></ul>
        <h5>Drop us an email at <a class="text-black" href="mailto:tiendakayakchile@gmail.com">tiendakayakchile@gmail.com</a> to apply.</h5>
    </div>
</template>

<script>
export default {
   name: "faq",
   mounted(){ window.scrollTo(0,0) },
   props: [],
   data() {
      return {}
   },
   computed: {},
   methods: {},
   components: {}
}
</script>

<style>
.faq h5 {
    font-weight: bold;
}
.faq p {
    color: var(--secondary);
}
</style>