var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-nav row pt-3 pb-2"},[_c('div',{staticClass:"col-12 col-md-6"},[(_vm.$mq > 2)?_c('h4',{staticClass:"border-bottom border-primary pb-2 w-fc all-caps"},[_vm._v("Kayak Chile")]):_c('h6',{staticClass:"border-bottom border-primary pb-2 w-fc all-caps"},[_vm._v("Kayak Chile")]),_c('p',[_vm._v("Camino Internacional 1330, Pucón, IX Región")]),_vm._m(0),_vm._m(1)]),_c('div',{staticClass:"col-6 col-md-3"},[(_vm.$mq > 2)?_c('h4',{staticClass:"pb-2 border-bottom w-fc"},[_vm._v(_vm._s(_vm.aboutUs[_vm.worldLanguage]))]):_c('h6',{staticClass:"py-2 border-bottom w-fc"},[_vm._v(_vm._s(_vm.aboutUs[_vm.worldLanguage]))]),_c('router-link',{staticClass:"text-black",attrs:{"to":{name: 'faq'}}},[_c('p',{on:{"click":_vm.preventNavToggle}},[_vm._v("FAQ")])]),_c('router-link',{staticClass:"text-black",attrs:{"to":{name: 'our-team'}}},[_c('p',{on:{"click":_vm.preventNavToggle}},[_vm._v(_vm._s(_vm.ourTeam[_vm.worldLanguage]))])]),_c('router-link',{staticClass:"text-black",attrs:{"to":{name: 'join-our-team'}}},[_c('p',{on:{"click":_vm.preventNavToggle}},[_vm._v(_vm._s(_vm.joinOurTeam[_vm.worldLanguage]))])])],1),_c('div',{staticClass:"col-6 col-md-3"},[(_vm.$mq > 2)?_c('h4',{staticClass:"pb-2 border-bottom border-danger w-fc"},[_vm._v(_vm._s(_vm.media[_vm.worldLanguage]))]):_c('h6',{staticClass:"py-2 border-bottom border-danger w-fc"},[_vm._v(_vm._s(_vm.media[_vm.worldLanguage]))]),_vm._m(2),_vm._m(3),_vm._m(4)]),_c('div',{staticClass:"col-12",class:_vm.$mq > 2 ? 'text-right' : 'mt-2'},[_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticClass:"text-black",attrs:{"href":"tel:+56 9 5824 4974"}},[_vm._v("+56 9 5824 4974")]),_vm._v(" | "),_c('a',{staticClass:"text-black",attrs:{"href":"tel:+56 9 6621 1541"}},[_vm._v("+56 9 6621 1541")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticClass:"text-black",attrs:{"href":"mailto:tiendakayakchile@gmail.com"}},[_vm._v("tiendakayakchile@gmail.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticClass:"text-black",attrs:{"href":"https://www.facebook.com/kayakchilepucon/","target":"_blank"}},[_vm._v("facebook")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticClass:"text-black",attrs:{"href":"https://www.instagram.com/kayakchile/","target":"_blank"}},[_vm._v("instagram")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticClass:"text-black",attrs:{"href":"https://www.tripadvisor.com/Attraction_Review-g294297-d5174438-Reviews-Kayak_Chile-Pucón_Araucania_Region.html","target":"_blank"}},[_vm._v("tripadvisor")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-secondary text-small m-0"},[_vm._v("Built by "),_c('a',{staticClass:"text-secondary",attrs:{"href":"https://www.github.com/porterwilcox","target":"_blank"}},[_vm._v("/porterwilcox")])])
}]

export { render, staticRenderFns }