import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Offering from '../views/Offering.vue'
import FAQ from '../views/FAQ.vue'
import OurTeam from '../views/OurTeam.vue'
import JoinOurTeam from '../views/JoinOurTeam.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/offering/:offeringName/:offeringOption?',
    name: 'offering',
    component: Offering
  },
  {
    path: '/faq',
    name: 'faq',
    component: FAQ
  },
  {
    path: '/our-team',
    name: 'our-team',
    component: OurTeam
  },
  {
    path: '/join-our-team',
    name: 'join-our-team',
    component: JoinOurTeam
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: function () {
  //     return import('../views/About.vue')
  //   }
  // }
]

const router = new VueRouter({
  routes
})

export default router
