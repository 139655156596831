<template>
    <div class="our-team">
       <div class="row">
           <div class="col-12 my-2">
                <h1 :class="$mq < 3 ? 'mt-3' : ''" class="pb-2 capitalized w-fc border-bottom text-kc-blue">{{ourTeam[worldLanguage]}}</h1>
                <hr class="w-100">
           </div>
       </div>
       <div class="row">
           <div class="col-12 col-md-4 position-relative">
               <img height="200px" :src="BenIMG">
               <h2 class="text-kc-blue opac-90">Ben May</h2>
               <h5 :class="$mq > 4  ? 'text-twist-r' : ''" class="text-danger ts-white">Founder and Trip Leader</h5>
           </div>
           <div class="col-12 col-md-8 d-flex align-items-center">
               <p class="text-muted">Owner of Kayak Chile, trip leader, guide, co-author of “Whitewater Chile” and general badass, Ben has been paddling for 27 years and guiding for 19. Practicing his skills on the tight ditches of Devon, England he soon moved on to the considerably larger rivers of Nepal and Chile. Ben lives full time in Chile although still has time for paddling trips back home in England, Norway and the East and West coast of the States in the last few years. He also spends some of the year in Uganda, managing <a href="http://kayakthenile.com/" class="text-black" target="_blank"> Kayak the Nile</a>, taking full advantage of paddling seasons worldwide.</p>
           </div>
       </div>
       <hr class="w-75">
       <div class="row">
           <div class="col-12 col-md-8 d-flex align-items-center">
               <p class="text-muted">Judith is new to kayaking but after her first lesson is now eager to improve. Last season Judith worked as a horse riding guide around Pucón. Now working for Kayak Chile, she brings a bit of her German order into the office and can always be found with a welcoming smile at the front desk. Outside of work she does some journalism, enjoys travelling around Chile and enjoying the lifestyle of Pucón. Judith has been living in Chile for two and a half years. She has fallen in love with the country and wants to stay much longer.</p>
           </div>
           <div :class="$mq > 4 ? '' : 'order-first'" class="col-12 col-md-4 position-relative d-flex flex-column align-items-end">
               <img height="200px" :src="JudyIMG" alt="judy">
               <h2 class="text-kc-blue opac-90">Judith Mintrop</h2>
               <h5 :class="$mq > 4  ? 'text-twist-l' : ''" class="text-danger ts-white">General Manager</h5>
           </div>
       </div>
       <hr class="w-75">
       <div class="row">
           <div class="col-12 col-md-4 position-relative">
               <img height="225px" :src="JoshIMG">
               <h2 class="text-kc-blue opac-90">Josh Stupka</h2>
               <h5 :class="$mq > 4  ? 'text-twist-r' : ''" class="text-danger ts-white">Marketing & Lead Guide</h5>
           </div>
           <div class="col-12 col-md-8 d-flex flex-column align-items-center">
               <p class="text-muted">Josh discovered whitewater kayaking at the age of 15, and during the summers of his high school years he would leave his hometown in Tupelo, Mississippi,  travel to the mountains of Wyoming and teach himself how to kayak on the rivers around Dubois and Jackson Hole.  It didn’t take long for riversports to become a passion and an obsession.  He became a raft guide at the age of 18 on the Ocoee River in Tennessee and soon felt the calling of the rivers and mountains of the American West.</p>                
               <p class="text-muted">At the age of 20, he moved to Gunnison, Colorado to attend Western Colorado University where he studied Wildlife Biology, Spanish, and Outdoor Recreation and Education while expanding his skills on the rivers and creeks of Colorado.  During the university years, he guided and instructed for the university outdoor program, spent a semester in Puerto Rico guiding sea kayaking and snorkeling tours, and helped start a rafting company in the jungles of Guatemala.</p>
                
                <p class="text-muted">With a bachelor’s degree in hand, Josh was soon pulled to the incredible and inspiring rivers of Southern Chile where he guided/safety kayaked on the Trancura and Futaleufu Rivers. During this time he met Ben May, owner of Kayak Chile, and they formed a partnership to make Kayak Chile the best outfitter in the region.</p>
                
                <p class="text-muted">Now, Josh spends the Northern Hemisphere summers in Gunnison, Colorado guiding luxury adventure travel trips for internationally acclaimed Eleven Experience; and the Southern Hemisphere summers running and guiding amazing trips with Kayak Chile located in Pucon.</p>
                
                <p class="text-muted">Josh is a Wilderness First Responder, American Canoe Association Level IV Kayak Instructor, Swiftwater Rescue certified, and organizer of the Gunnison River Festival in Gunnison, Colorado. </p>
           </div>
       </div>
    </div>
</template>

<script>
import BenIMG from '@/assets/ben.jpg'
import JudyIMG from '@/assets/judy-prof.jpg'
import JoshIMG from '@/assets/josh-prof.jpg'


export default {
   name: "our-team",
   mounted(){ 
      window.scrollTo(0,0)
   },
   props: [],
   data() {
      return {
          BenIMG,
          JudyIMG,
          JoshIMG,
          ourTeam: {
            en: "Our Team",
            es: "Nuestro Equipo",
            de: "Unser Team",
            pt: "Nosso Time"
         },
      }
   },
   computed: {
       worldLanguage() { return this.$store.state.worldLanguage }
   },
   methods: {},
   components: {}
}
</script>

<style>
.text-twist-r {
    transform: rotate(-90deg);
    position: absolute;
    right: 0;
    bottom: 50%;
}
.text-twist-l {
    transform: rotate(90deg);
    position: absolute;
    left: 0;
    bottom: 50%;
}
</style>