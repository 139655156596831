<template>
    <div class="cards-container py-3 bg-dark3">
       <div v-for="(cardData, i) in offeringsDict" :key="i" class="col-12 col-md-6 col-lg-4">
           <div v-if="finishedLoading" class="card my-3 card-custom bg-none action" :style="'background-image: url(' + cardsBGs[i] + ');'" @click="preventNavToggle(); $router.push({name: 'offering', params: { offeringName: cardData.linkTo, offeringOption: cardData.options ? cardData.options[0].linkTo : undefined }})">
                <div :class="{'d-flex flex-column-reverse': cardData.linkTo == 'family-float' && worldLanguage != 'en' }" class="card-body text-white ts-black">
                    <h3 :class="{'text-right': cardData.linkTo == 'family-float' && worldLanguage == 'en' }" class="card-title capitalized">{{cardData.title}}</h3>
                    <h6 class="card-subtitle">{{cardData.subtitle}}</h6>
                </div>
           </div>
       </div>
    </div>
</template>

<script>
import School from '@/assets/school.jpg'
import DuoCard from '@/assets/duo-card.jpg'
import MomDaughterFF from '@/assets/MomDaughterFF.jpg'
import Ducky from '@/assets/ducky.jpg'
import Multiday from '@/assets/possible-multiday.jpg'
import Advanced from '@/assets/nevados-advanced.jpg'

export default {
   name: "cards-container",
   props: {},
   data() {
      return {
         cardsBGs: [School, DuoCard, MomDaughterFF, Ducky, Multiday, Advanced]
      }
   },
   computed: {
      worldLanguage() { return this.$store.state.worldLanguage },
      offeringsDict() { return this.$store.state.offeringsDict[this.worldLanguage].filter(c => c.linkTo != 'rentals') },
      finishedLoading() {
          return this.$store.state.killLoader
      }
   },
   methods: {
       preventNavToggle() { this.$store.commit('preventNavToggle') }
   },
   components: {}
}
</script>

<style>
.card-custom {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 35vh;
    filter: brightness(90%);
    transition: all .25s linear;
}
.card-custom:hover {
    filter: brightness(100%);
    box-shadow: -1px 2px 5px var(--light);
}
.card-custom h6 {
    opacity: 0;
    padding: 10px;
    background: transparent;
    transition: all .5s linear;
}
.card-custom:hover h6 {
    opacity: 1;
    background: rgba(0, 0, 0, 0.75);
}
</style>