<template>
    <div class="rentals row">
        <div v-for="(n,i) in 3" :key="'rent-card'+i" class="col-12 col-md-4">
            <div class="rental-card m-2">
                <div v-html="rentals[worldLanguage][i]" class="rental-card-header h35 d-flex justify-content-center align-items-center flex-column"></div>
                <div class="card-footer text-kc-blue bg-none text-center">
                    <h2>{{i == 0 ? '$30 | $150' : i == 1 ? '$40 | $200' : '$60 | $300'}}</h2>
                    <h4 class="text-secondary">{{prices[worldLanguage]}}</h4>
                </div>
            </div>
        </div>
        <hr class="w-75">
        <div class="col-12 col-md-8 offset-md-2">
            <div v-for="(label, i) in detailsLabels[worldLanguage]" :key="'dets'+i">
                <h5>{{label}}</h5>
                <p>{{detailsBodies[worldLanguage][i]}}</p>
            </div>
            <hr class="w-100">
            <p class="text-danger"><i>{{warning[worldLanguage]}}</i></p>
        </div>
        <book-your-trip class="col-12">
            <h3 v-text="offeringTitleData.title" class="capitalized text-white text-center custom-left"></h3>
        </book-your-trip>
    </div>
</template>

<script>
import BookYourTrip from '@/components/BookYourTrip.vue'

export default {
   name: "rentals",
   props: [],
   data() {
      return {
          rentals: {
              en: ["<h1>Kayak</h1>", "<h2>Kayak</h2><p>&</p><h3>Paddle</h3>", "<h2>Kayak,</h2><h3>Paddle</h3><p>&</p><h5>Complete Whitewater Equipment</h5>"],
              es: ["<h1>Kayak</h1>", "<h2>Kayak</h2><p>y</p><h3>Remo</h3>", "<h2>Kayak,</h2><h3>Remo</h3><p>y</p><h5>Todo Equipo de Aguas Blancas</h5>"],
              de: ["<h1>Kajak</h1>", "<h2>Kajak</h2><p>&</p><h3>Paddel</h3>", "<h2>Kajak,</h2><h3>Paddel</h3><p>&</p><h6>Komplette Ausrüstung fürs Wildwasserkajaken</h6>"],
              pt: ["<h1>Kayak</h1>", "<h2>Kayak</h2><p>&</p><h3>Remo</h3>", "<h2>Kayak,</h2><h3>Remo</h3><p>&</p><h6>Equipamento completo para águas bravas</h6>"]
          },
          prices: {
              en: 'Per Day | Per Week',
              es: 'Por Día | Por Semana',
              de: 'Pro Tag | Pro Woche',
              pt: 'Por Dia | Por Semana',
          },
          detailsLabels: {
              en: ['To Book', 'Pick Up', 'Return'],
              es: ['Para Reservar', 'Colleción', 'Regreso'],
              de: ['Zum Reservieren', 'Abholung', 'Zurück bringen'],
              pt: ['Agendar', 'Coletar', 'Retornar'],
          },
          detailsBodies: {
              en: ['Pay a 50% deposit to reserve your boat (and gear).', 'Rentals may be picked up from our office in Pucón, Camino Internacional 1330.', 'Rentals must be returned by 9pm to avoid paying an additional day of rent.'],
              es: ['Pague un depósito del 50% y su kayak no será arrendado a nadie más en los días que haya solicitado', 'Recoger su kayak de nuestra tienda en Pucón, Camino Internacional 1330.', 'Debe que devolver su kayak antes de 21:00.'],
              de: ['Mache eine Anzahlung von 50% und dein Kajak steht sicher für dich bereit, wenn du ankommst.', 'Hole dein Kajak hier in unserem Laden in Pucón, Camino Internacional 1330.', 'Bringe das Kajak vor 21 Uhr zurück.'],
              pt: ['Pague um depósito de 50% e seu barco não será alugado para mais ninguém nos dias que você solicitou.', 'Os aluguéis podem ser adquiridos em nosso escritório em Pucón, Camino Internacional 1330.', 'O aluguel deve ser devolvido às 21h para evitar o pagamento de um dia adicional de aluguel.'],
          },
          warning: {
              en: 'Broken or lost boats are the responsibility of the renter and have to be replaced with the same model or a fee of $850USD',
              es: 'Los botes rotos o perdidos son responsabilidad del arrendatario y deben ser reemplazados por el mismo modelo o una tarifa de $850USD.',
              de: 'Defekte oder verlorene Boote liegen in der Verantwortung des Mieters und müssen durch dasselbe Modell oder eine Gebühr von 850 USD ersetzt werden.',
              pt: 'Barcos quebrados ou perdidos são de responsabilidade do locatário e têm que ser substituídos pelo mesmo modelo ou uma taxa de $850USD.',
          }
      }
   },
   computed: {
       worldLanguage() { return this.$store.state.worldLanguage },
       offeringName() { return this.$route.params.offeringName },
       offeringTitleData() {
           return this.$store.state.offeringsDict[this.worldLanguage].find(o => o.linkTo === this.offeringName)
       },
   },
   methods: {},
   components: { BookYourTrip }
}
</script>

<style>
.rental-card-header *{
    margin: 0;
}
.cache-busting {
    opacity: 1;
}
</style>