<template>
    <div class="trip-advisor py-5">
        <div class="col-12 col-md-5 d-flex justify-content-center align-items-center">
            <img :src="TripAdvisor" class="mb-2 trip-advisor-logo" :height="$mq > 2 ? '200px' : '100px'"/>
        </div>
        <div class="col-12 col-md-7 ta-caro carousel slide text-white" data-ride="carousel" data-interval="false">
            <div class="carousel-inner h-100">
                <div v-for="(review, i) in reviews" :key="i" :class="i == 0 ? 'active' : ''" class="carousel-item h-100">
                    <div class="d-flex flex-column align-items-center justify-content-center h-100">
                        <h3>{{review.title}}</h3>
                        <div class="w-50 border-teal mt-2 mb-3"></div>
                        <div class="w-100 d-flex justify-content-center">
                            <h5 class="w-75">{{review.body}}<span class="text-secondary">{{review.author}}</span></h5>
                            <img v-if="finishedLoading" :src="review.img" class="reviewer-img"> 
                        </div>
                    </div>
                </div>
            </div>
            <a class="carousel-control-prev" href=".ta-caro" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon mr-5" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href=".ta-caro" role="button" data-slide="next">
                <span class="carousel-control-next-icon ml-5" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>
</template>

<script>
import AaronTP from '@/assets/aaron-tp.jpg'
import SamTP from '@/assets/sam-tp.jpg'
import RobinRodTP from '@/assets/robin-rod-tp.jpg'
import TripAdvisor from '@/assets/trip-advisor.jpg'

export default {
   name: "trip-advisor",
   props: {},
   data() {
      return {
          TripAdvisor,
          reviews: [{
              title: '“Highlight of Pucon!”',
              body: "Ended up in Pucon on a whim and was suggested kayak Chile by chili kiwi house and I'm so glad I did this trip because of it. My buddy and I went out with Josh and Angelo and it was one of the highlights to our time in Pucon. The kayaking was incredible and Josh and Angelo were super cool to go out on the water with. Felt like a paddle trip with friends and between the rapids and the scenery, the time absolutely flew by. Don't hesitate on this kayak trip. Just book it now. You won't regret it. I promise ",
              author: '- Craig Bothwell',
              img: 'https://media-cdn.tripadvisor.com/media/photo-s/01/2e/70/99/avatar025.jpg'
          },{
              title: '“Heaps of fun, excellent guides!”',
              body: 'We had heaps of fun, well supported by knowledgeable and professional guides. (...) A great introduction to this sport ',
              author: '- RobinandRod',
              img: RobinRodTP
          },{
              title: '"Great Family Float"',
              body: 'Our family of five with kids age 8, five, four loved the family float. (...) It was a nice relaxing trip which was neither too adventurous nor too boring for any of us. (...) Highly recommended! ',
              author: '- Aaron B',
              img: AaronTP
          },{
              title: '"Advanced kayak school"',
              body: 'Ben (big) took the time to understand our abilities and provided good recommendations for a days paddle. (...) The rivers they recommended provided a good warm up and then a bit of a challenge.(...) Highly recommended! ',
              author: '- Sam T',
              img: SamTP
          }]
      }
   },
   computed: {
       finishedLoading() {
         return this.$store.state.killLoader 
      }
   },
   methods: {},
   components: {}
}
</script>

<style>
.trip-advisor-logo {
    border-radius: 10%;
    background-color: #e5e5e5;
}
.reviewer-img {
    height: 50px;
    border-radius: 50%;
}
.border-teal {
    border-bottom: solid 1px #24a785;
}
</style>