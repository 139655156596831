<template>
    <div data-backdrop="static" class="modal contact-modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content bg-light">
                <div class="modal-header d-flex justify-content-between">
                    <h4 class="modal-title text-kc-blue">{{contact[worldLanguage]}}</h4>
                    <button type="button" class="btn btn-outline-dark" data-dismiss="modal">X</button>
                </div>
                <form action="https://formspree.io/tiendakayakchile@gmail.com" method="POST" class="modal-body">
                    <div v-for="(fg, i) in fgSpecs" :key="'form-g'+i" class="form-group">
                        <label v-if="fg.type == 'dropdown'">{{formLabels[worldLanguage][i]}}</label>
                        <select class="w-100" v-if="fg.type == 'dropdown'" :value="selectDefaultVal" :name="fg.name" :required="fg.required">
                            <option class="capitalized" v-for="(o, j) in dropdownOptions" :key="'opt' +j" :value="o">{{o}}</option>
                        </select>
                        <textarea :placeholder="formLabels[worldLanguage][i]" v-else-if="fg.type == 'textarea'" :name="fg.name" cols="30" rows="5" class="w-100"></textarea>
                        <input :placeholder="formLabels[worldLanguage][i]" v-else :name="fg.name" :required="fg.required" :type="fg.type" class="form-control">
                        <small v-if="fg.message" class="ml-3 form-text text-muted">{{fg.message}}</small>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-outline-dark" data-dismiss="modal">{{{'en': 'cancel', 'es': 'cancelar', 'pt': 'cancelar', 'de': 'stornieren' }[worldLanguage]}}</button>
                        <button type="submit" class="btn btn-outline-primary">{{send[worldLanguage]}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
   name: "contact-modal",
   props: [],
   data() {
      return {
          contact: {
              en: 'Contact',
              pt: 'Contato',
              de: 'Kontakt',
              es: 'Contacto'
          },
          send: {
              en: 'Send Message',
              pt: 'Enviar Mensagem',
              de: 'Nachricht Senden',
              es: 'Enviar Mensaje'
          },
          contact: {
              en: 'Contact',
              pt: 'Contato',
              de: 'Kontakt',
              es: 'Contacto'
          },
          formLabels: {
              en: ['Name', 'Email', 'Phone', 'Subject', 'Years Experience', 'Additional Information'],
              es: ['Nombre', 'Email', 'Número de Telefono', 'Línea de Asunto', 'Años de Experiencia', 'Mas información'],
              de: ['Name', 'Email', 'Telefon Nummer', 'Betreffzeile', 'Langjährige Erfahrung', 'Extra Information'],
              pt: ['Nome', 'Email', 'Número de Telefone', 'Linha de Assunto', 'Anos de Experiência', 'Mais Informações'],
          },
          fgSpecs: [{type: 'text', name: 'Name', required: true}, {type: 'email', name: 'email', required: true, message: "We'll never share your email with anyone else."}, {type: 'phone', name: 'phone', required: false}, {type: 'dropdown', name: 'Subject', required: true}, {type: 'text', name: 'Years of Experience', required: false}, {type: 'textarea', name: 'Additional Info', required: false}]
      }
   },
   computed: {
       worldLanguage() { return this.$store.state.worldLanguage },
       dropdownOptions() {
           let opts = this.$store.state.offeringsDict[this.worldLanguage].map(o => o.title)
           opts.push({
            en: "Join Our Team",
            es: "Unete A Nuestro Equipo",
            de: "Trete Unserem Team Bei",
            pt: "Junte-se A Nossa Equipe"
         }[this.worldLanguage])
           opts.push({en: 'Other', es: 'Otro', de: 'Andere', pt: 'de outros'}[this.worldLanguage])
           return opts
       },
       selectDefaultVal() {
           let linkTo = this.$route.params.offeringName
           if(!linkTo) return "Other"
           let deets = this.$store.state.offeringsDict[this.worldLanguage].find(o => o.linkTo == linkTo)
           return deets.title
       }
   },
   methods: {},
   components: {}
}
</script>