<template>
    <div class="navigation col-8 col-md-2 p-0 side-nav-index">
       <div class="collapse bg-dark side-nav h100">
            <div class="d-flex w-100 justify-content-center">
               <img class="my-3 kayak-logo action" :src="KCLogo" alt="kayak-chile-logo" :height="$mq | mq({1: '50px', 2: '100px', 3: '75px', 4: '150px', 5: '150px'})" @click="$store.commit('killLoader'); $router.push({name: 'home'})"/>
            </div>
            <ul class="navbar-nav ml-2 row align-items-start">
               <li v-for="(linkData, i) in offeringsDict[worldLanguage]" :key="i" class="nav-item">
                  <router-link v-if="!linkData.options" :class="$route.name != 'offering' ? 'text-white' : $route.params.offeringName == linkData.linkTo ? 'text-white' : 'text-secondary' " class="nav-link all-caps" :to="{name: 'offering', params: { offeringName: linkData.linkTo }}"><h6 :class="$mq < 3 ? 'm-0' : ''">{{linkData.title}}</h6></router-link>
                  <div @mouseenter="collapseHoverToggle('.'+linkData.linkTo+'-collapse', 'show')" @mouseleave="collapseHoverToggle('.'+linkData.linkTo+'-collapse', 'hide')" v-else>
                     <h6 :class="$route.name != 'offering' ? 'text-white' : $route.params.offeringName == linkData.linkTo ? 'text-white' : 'text-secondary' " class="nav-link all-caps">{{linkData.title}}</h6>
                     <div :class="$mq <= 2 ? 'show' : linkData.linkTo+'-collapse'" class="collapse">
                        <router-link  v-for="(o, j) in linkData.options" :key="'opt'+j" class="nav-link all-caps" :class="$route.name != 'offering' ? 'text-white' : $route.params.offeringName == linkData.linkTo & $route.params.offeringOption == o.linkTo ? 'text-white' : 'text-secondary' " :to="{name: 'offering', params: {offeringName: linkData.linkTo, offeringOption: o.linkTo}}"><p class="m-0" :class="$mq < 3 ? 'text-smaller ml-3' : ''">| {{o.title}}</p></router-link>
                        <p :class="{'text-white': $route.name != 'offering', 'text-secondary': $route.name == 'offering', 'text-smaller ml-3': $mq < 3}" class="action nav-link" @click="viewMultidaysInEnglish" v-if="worldLanguage != 'en' && linkData.linkTo == 'multiday'">| View all trips in English</p>
                     </div>
                  </div>
               </li>
               <div v-if="$mq > 2" class="d-flex flex-column text-secondary">
                  <i class="action" @click="setWorldLanguage('es')" v-if="worldLanguage != 'es'">Español</i>
                  <i class="action" @click="setWorldLanguage('en')" v-if="worldLanguage != 'en'">English</i>
                  <i class="action" @click="setWorldLanguage('de')" v-if="worldLanguage != 'de'">Deutsch</i>
                  <i class="action" @click="setWorldLanguage('pt')" v-if="worldLanguage != 'pt'">Português</i>
               </div>
               <div v-else class="d-flex text-secondary w-75 justify-content-between">
                  <i class="action" @click="setWorldLanguage('es')" v-if="worldLanguage != 'es'">es</i>
                  <i class="action" @click="setWorldLanguage('en')" v-if="worldLanguage != 'en'">en</i>
                  <i class="action" @click="setWorldLanguage('de')" v-if="worldLanguage != 'de'">de</i>
                  <i class="action" @click="setWorldLanguage('pt')" v-if="worldLanguage != 'pt'">pt</i>
               </div>
            </ul>
        </div>
    </div>
</template>

<script>
import KCLogo from '@/assets/KayakChile-logo.jpg'

export default {
   name: "navigation",
   mounted() {
      $('.side-nav').collapse(this.$mq <= 2 ? 'hide' : 'show')
   },
   props: [],
   data() {
      return {
         collapseId: '',
         KCLogo,
         collapseTimers: {
            '.family-float-collapse': false,
            '.multiday-collapse': false
         }
      }
   },
   computed: {
      worldLanguage() { return this.$store.state.worldLanguage },
      offeringsDict() { return this.$store.state.offeringsDict },
      offeringOption() { return this.$route.params.offeringOption }
   },
   methods: {
      setWorldLanguage(lng) {
         this.$store.commit('worldLanguage', lng)
      },
      collapseHoverToggle(idStr, action) {
         if (this.$mq <= 2) return
         if(action == 'hide') {
            clearTimeout(this.collapseTimers[idStr])
            $(idStr).collapse(action)
         } else {
            this.collapseTimers[idStr] = setTimeout(() => { $(idStr).collapse(action) }, 200)
         }
      },
      viewMultidaysInEnglish() {
         this.setWorldLanguage('en')
         this.$router.push({ name: 'offering', params: { offeringName: 'multiday', offeringOption: '10-days-futaleufu' } })
      }
   },
   components: {},
   watch: {
      offeringOption: {
         handler(val) {
            if(val && this.$mq > 2) { $(this.collapseId = '.'+this.$route.params.offeringName+'-collapse').collapse('show') }
            else $(this.collapseId).collapse('hide')
         }
      }
   }
}
</script>

<style>
.kayak-logo {
   border-radius: 10%;
}
.side-nav-index {
   z-index: 1048;
}
.navigation {
   min-width: fit-content!important;
}
</style>