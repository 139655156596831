<template>
    <div class="generic-offering-bulk-template">
       <div class="w-100">
            <div class="d-flex justify-content-center align-items-center">
                <h3 :class="showDetails ? 'text-black' : 'action text-kc-blue text-90'" class="text-center" @click="showDetails = true">{{{en: 'Details', es: 'Detalles', de: 'Einzelheiten', pt: 'Detalhes'}[worldLanguage]}} |</h3>
                <h3 :class="!showDetails ? 'text-black' : 'action text-kc-blue text-90'" class="text-center" @click="showDetails = false">| {{{en: 'Description', es: 'Descripción', de: 'Beschreibung', pt: 'Descrição'}[worldLanguage]}}</h3> 
            </div>
            <hr class="w-50">
        </div>
        <div class="col-12 col-md-10 offset-md-1 table pt-3">
            <div v-if="showDetails" class="row">
                <div class="col-12 col-md-6">
                    <div v-for="(label, i) in offeringTableLabels" :key="i">
                        <div class="d-flex flex-column" v-if="i <= 5">
                            <p><b class="capitalized">{{label}}</b> - {{tableSpecs[i]}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div v-for="(label, i) in offeringTableLabels" :key="i">
                        <div class="d-flex flex-column" v-if="i > 5">
                            <p><b class="capitalized">{{label}}</b> - {{tableSpecs[i]}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-else>
                <div class="col-12" v-html="offeringDescriptions"></div>
            </div>
        </div>
        <!-- NOTE videos here when youtube link works -->
        <div v-if="youtubeEmbedId" class="row pb-4">
            <hr class="w-75">
            <div class="col-12 d-flex justify-content-center">
                <div class="w60 h45">
                    <iframe :src="'https://www.youtube.com/embed/'+youtubeEmbedId" width="100%" height="100%" frameborder="0" allowfullscreen="allowfullscreen"
        mozallowfullscreen="mozallowfullscreen" 
        msallowfullscreen="msallowfullscreen" 
        oallowfullscreen="oallowfullscreen" 
        webkitallowfullscreen="webkitallowfullscreen"></iframe>
                </div>
            </div>
        </div>
        <book-your-trip class="col-12 p-0">
            <div v-html="offeringPrices" class="text-white text-center custom-left"></div>
        </book-your-trip>
    </div>
</template>

<script>
import BookYourTrip from '@/components/BookYourTrip.vue'


export default {
   name: "generic-offering-bulk-template",
   props: [],
   data() {
      return {
        showDetails: true,
        youtubeEmbedIds: {
            'family-float': 'gVYzT-dSkxA',
            'double-kayak': 'BdH3s5r81RM',
            'kayak-school': '9GF6uznWhNU',
            'overnight-san-pedro': 'iLsXCWCAdMs',

        }
      }
   },
   computed: {
       worldLanguage() { return this.$store.state.worldLanguage },
       offeringName() { return this.$route.params.offeringName },
       offeringOption() { return this.$route.params.offeringOption },
       offeringTitleData() {
           return this.$store.state.offeringsDict[this.worldLanguage].find(o => o.linkTo === this.offeringName)
       },
       offeringOptionTitleData() {
           return this.offeringTitleData.options.find(opt => opt.linkTo == this.offeringOption)
       },
       offeringTableLabels() { return this.$store.state.offeringTableLabels[this.worldLanguage] },
       tableSpecs() { return this.$store.state.tableSpecs[this.worldLanguage][this.offeringOption || this.offeringName] },
       offeringPrices() { return this.$store.state.offeringPrices[this.worldLanguage][this.offeringOption || this.offeringName] },
       offeringDescriptions() { return this.$store.state.offeringDescriptions[this.worldLanguage][this.offeringOption || this.offeringName] },
       youtubeEmbedId() { return this.youtubeEmbedIds[this.offeringOption || this.offeringName] || false }
   },
   methods: {},
   components: { BookYourTrip }
}
</script>