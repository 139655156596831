<template>
    <div class="app container-fluid">
      <div class="row">
        <div class="always-top ml-1 mt-2" v-if="$mq <= 2">
          <h3 @click="toggle++" v-if="toggle%2" data-target=".side-nav" data-toggle="collapse" class="ts-white pb-5 pr-5">&#8801;</h3>
          <h3 @click="toggle++" v-else data-target=".side-nav" data-toggle="collapse" class="ml-1 text-white">&ldrushar;</h3>
        </div>
        <navigation class="position-fixed h100" />
        <div class="col-12 col-md-10 offset-md-2">
          <router-view />
        </div>
        <footer-nav class="col-12 col-md-10 offset-md-2 bg-light" />
      </div>
    </div>
</template>

<script>
import Navigation from '@/components/Navigation.vue'
import FooterNav from '@/components/FooterNav.vue'

export default {
   name: "app",
   mounted() {
    let firstImg = document.querySelector('img.josh-smile')
    if (!firstImg) return
    firstImg.addEventListener('load', this.killLoader)
   },
   props: [],
   data() {
      return {
        toggle: 3
      }
   },
   computed: {
      offeringName() { return this.$route.params.offeringName },
      offeringOption() { return this.$route.params.offeringOption },
      preventNavToggle() { return this.$store.state.preventNavToggle }
   },
   methods: {
     killLoader() { this.$store.commit('killLoader') }
   },
   components: { Navigation, FooterNav },
   watch: {
     toggle: {
       immediate: true,
       handler(val) {
         if(val%2) setTimeout(()=> {document.querySelector('.navigation').classList.remove('side-nav-index')}, 250)
         else document.querySelector('.navigation').classList.add('side-nav-index')
          }
        },
       offeringName: {
         handler() {
           if(this.$mq <= 2) { 
             if(!this.preventNavToggle) this.toggle++
             $('.side-nav').collapse('hide') 
            }
         }
        }
     }
   }
</script>

<style>
/* globals */
/* #3670a9 */
:root {
  --dark: #171819;
  --dark2: #1c1d1f;
  --dark3: #212a3a;
  --white: #f4f7f9;
  --grey: #606b73;
  --baige: #f6f3ea;
  --link-green: #74a893;
  --kc-blue: #35459c; 
}
body {
  /* font-family: ff-mark-web-pro,Helvetica Neue,Helvetica,Arial,sans-serif; */
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  font-weight: 300;
  overflow-x: hidden;
}
.text-black {
  color: black!important;
}
.text-white {
  color: var(--white)!important;
}
.text-grey {
  color: var(--grey)!important;
}
.text-kc-blue {
  color: var(--kc-blue)!important;
}
.text-black {
  color: black!important;
}
.text-small {
  font-size: 75%;
}
.text-smaller {
  font-size: 50%!important;
}
.text-90 {
  font-size: .99rem;
  transition: all .15s linear;
}
.ts-black {
  text-shadow: 0 0 5px black;
}
.ts-white {
  text-shadow: 0 0 5px white;
}
.bg-dark {
  background: var(--dark)!important;
}
.bg-dark2 {
  background: var(--dark2)!important;
}
.bg-dark3 {
  background: var(--dark3)!important;
}
.bg-none {
  background-color: transparent!important;
}
.w100 {
  width: 100vw;
}
.h100 {
  height: 100vh;
}
.h90 {
  height: 90vh;
}
.h45 {
  height: 45vh;
}
.h35 {
  height: 35vh;
}
.h25 {
  height: 25vh;
}
.h20 {
  height: 20vh;
}
.h10 {
  height: 10vh;
}
.w-fc {
  width: fit-content;
}
.w-90 {
  width: 90%;
}
.w60 {
  width: 60vh;
}
.action {
  cursor: pointer;
}
.capitalized {
  text-transform: capitalize;
}
.all-caps {
  text-transform: uppercase;
}
.always-top {
   z-index: 1049;
   position: fixed;
}
.opac-90 {
  opacity: .9;
}
.border-none {
  border: none;
}
/* /------------ */

/* mobile styling */
@media(max-width: 768px) {
    .card-custom {
        filter: brightness(100%);
    }
    .d-none {
      display: block!important;
    }
    .custom-left {
      box-shadow: none!important;
    }
    .h90 {
      height: 50vh;
    }
  }
</style>