<template>
    <div class="home">
       <div :class="killLoader ? 'destroy' : 'd-flex flex-column justify-content-center align-items-center loader'">
          <h1 class="text-white kc-border">KAYAKCHILE</h1>
          <div class="d-flex justify-content-center align-items-end">
             <h6 class="text-kc-blue mb-0 mr-1">loading</h6>
             <div class="dot"></div>
             <div class="dot"></div>
             <div class="dot"></div>
          </div>
       </div>
       <!-- NOTE caro-start -->
      <div id="home-carousel" :data-interval="$mq > 2 ? '3000' : '5000'" class="carousel carousel-fade slide row h90" data-ride="carousel">
         <ol class="carousel-indicators">
         <li data-target="#home-carousel" data-slide-to="0" class="active"></li>
         <li data-target="#home-carousel" data-slide-to="1"></li>
         <li data-target="#home-carousel" data-slide-to="2"></li>
         <li data-target="#home-carousel" data-slide-to="3"></li>
         </ol>
         <div class="carousel-inner position-relative">
            <div class="carousel-item active">
               <div class="caro-text-container slide-left h-25 position-absolute w-100 d-flex justify-content-center align-items-end">
                  <h2 class="caro-text ts-black">{{{en: 'Paddling experiences for all ages and abilities!', pt: 'Experiências fluviais para todas as idades e habilidades', de: 'Paddelerlebnisse für alle Altersgruppen und Könnerstufen', es: 'Experiencias del río para todas las edades y habilidades'}[this.worldLanguage]}}</h2>
               </div>
               <img v-if="!joshHR" :class="joshHR ? 'd-none' : 'd-block w-100 h90'" :src="JoshSmileLR"
                  class="josh-smile" alt="" loading="eager" />
               <img :class="joshHR ? 'd-block w-100 h90' : 'd-none'" @load="joshHR = true" :src="JoshSmile"
                   />
            </div>
            <div class="carousel-item">
               <div class="caro-text-container slide-half-left h-25 position-absolute w-90 d-flex justify-content-end align-items-end">
                  <h2 class="caro-text ts-black">{{{en: 'Family Float', pt: 'Passeio Familiar', de: 'Familien Float', es: 'Flotadas Familiares'}[this.worldLanguage]}}</h2>
               </div>
               <img v-if="!familyHR" :class="familyHR ? 'd-none' : 'd-block w-100 h90'" :src="FamilyLR"
                   alt="" loading="eager" />
               <img :class="familyHR ? 'd-block w-100 h90' : 'd-none'" @load="familyHR = true" :src="Family"
                   />
            </div>
            <div class="carousel-item">
               <div class="caro-text-container slide-top h-75 position-absolute w-90 d-flex justify-content-end align-items-end">
                  <h2 class="caro-text ts-black">{{{en: 'Double Kayak', pt: 'Kayak Duplo', de: 'DoppleKajak', es: 'Kayak Doble'}[this.worldLanguage]}}</h2>
               </div>
               <img v-if="!doublesHR" :class="doublesHR ? 'd-none' : 'd-block w-100 h90'" :src="DoublesLR"
                   alt="" loading="eager" />
               <img :class="doublesHR ? 'd-block w-100 h90' : 'd-none'" @load="doublesHR = true" :src="Doubles"
                   />
            </div>
            <div class="carousel-item">
               <div class="caro-text-container slide-diag h-25 position-absolute w-50 d-flex justify-content-end align-items-end">
                  <h2 class="caro-text ts-black">{{{en: 'Advanced Classes & Guiding', pt: 'Aulas Avançadas', de: 'Expertenklase & Führen', es: 'Clases Avanzadas'}[this.worldLanguage]}}</h2>
               </div>
               <img v-if="!palguinHR" :class="palguinHR ? 'd-none' : 'd-block w-100 h90'" :src="PalguinSaltoTresLR"
                   alt="" loading="eager" />
               <img :class="palguinHR ? 'd-block w-100 h90' : 'd-none'" @load="palguinHR = true" :src="PalguinSaltoTres"
                   />
            </div>
         </div>
      </div>
      <!-- caro fin -->
      <book-your-trip />
      <cards-container class="row" />
      <trip-advisor class="row bg-dark custom-left" />
    </div>
</template>

<script>
import JoshSmile from '@/assets/josh-smile.jpg'
import JoshSmileLR from '@/assets/josh-smile-lr.jpg'
import Family from '@/assets/family.jpg'
import FamilyLR from '@/assets/family-lr.jpg'
import Doubles from '@/assets/better-doubles.jpg'
import DoublesLR from '@/assets/better-doubles-lr.jpg'
import PalguinSaltoTres from '@/assets/palguin-salto-tres.jpg'
import PalguinSaltoTresLR from '@/assets/palguin-salto-tres-lr.jpg'
import CardsContainer from '@/components/CardsContainer.vue'
import TripAdvisor from '@/components/TripAdvisor.vue'
import BookYourTrip from '@/components/BookYourTrip.vue'

export default {
   name: "home",
   mounted(){ 
      window.scrollTo(0,0)
      if (this.$mq < 2) return
      window.addEventListener('wheel', () => {
         setTimeout(() => {
            let e = document.querySelector('p>b')
            if (!e) return
            e.parentNode.removeChild(e)
         }, 1000)
      }, {once: true})
   },
   props: {},
   data() {
      return { JoshSmile, JoshSmileLR, Family, FamilyLR, Doubles, DoublesLR, PalguinSaltoTres, PalguinSaltoTresLR, joshHR: false, familyHR: false, doublesHR: false, palguinHR: false,
      }
   },
   computed: {
      worldLanguage() { return this.$store.state.worldLanguage },
      killLoader() { 
         setTimeout(() => {
            let d = document.querySelector('.destroy')
            if(!d) return
            d.parentNode.removeChild(d)
         }, 100)
         return this.$store.state.killLoader 
      },
      finishedLoading() {
         return this.$store.state.killLoader 
      }
   },
   methods: {},
   components: { CardsContainer, TripAdvisor, BookYourTrip }
}
</script>

<style>
.caro-text-container {
    z-index: 1;
    animation: placeholder 1s 1 cubic-bezier(0.985, 0.005, 0.265, 1);
    animation-fill-mode: forwards;
  }
  .slide-left {
    left: -100%;
    animation-name: slide-left;
  }
  .slide-half-left {
    left: -50%;
    animation-name: slide-left;
  }
  .slide-top {
    top: -50%;
    animation-name: slide-top;
  }
  .slide-bottom {
    bottom: 0;
    animation-name: slide-bottom;
  }
  .slide-diag {
    bottom: 25%;
    right: 5%;
    animation-name: slide-diag;
  }
  @keyframes slide-left {
    100% {
      left: 0;
    }
  }
  @keyframes slide-top {
    100% {
      top: 0;
    }
  }
  @keyframes slide-bottom {
    100% {
      bottom: 80%;
    }
  }
  @keyframes slide-diag {
    100% {
      bottom: 75%;
      right: 50%;
    }
  }
  .caro-text {
    font-size: 3vw;
    color: white;
    border: 3px solid white;
    border-left: none;
    border-right: none;
    /* padding: 0 10px; */
    font-family: 'oswald', 'HelveticaNeue', 'Helvetica Neue', 'Helvetica-Neue', Helvetica, Arial, sans-serif;
  }
  .custom-left {
     box-shadow: inset 1px 0 var(--light);
  }
  .loader {
     position: fixed;
     height: 100vh;
     width: 100%;
     top: 0;
     left: 0;
     z-index: 99999999999;
     background: black;
  }
  .kc-border {
     border-bottom: 1px solid #e11f27;
  }
  .dot {
     margin-bottom: 3px;
     width: 5px;
     height: 5px;
     border-radius: 50%;
     background: var(--kc-blue);
     opacity: .3;
     animation-name: opacChange;
     animation-duration: 1.5s;
     animation-iteration-count: infinite;
  }
  .dot:nth-of-type(2) {
     margin: 0 5px 3px;
     animation-delay: .1s;
  }
  .dot:last-of-type {
     animation-delay: .2s;
  }
  @keyframes opacChange {
     0% { opacity: .3; }
     100% { opacity: 1; }
  }
  @media(max-width: 768px) {
    .caro-text {
      border: 1px solid white;
      border-left: none;
      border-right: none;
    }
  }
</style>