<template>
    <div class="footer-nav row pt-3 pb-2">
       <div class="col-12 col-md-6">
           <h4 v-if="$mq > 2" class="border-bottom border-primary pb-2 w-fc all-caps">Kayak Chile</h4>
           <h6 v-else class="border-bottom border-primary pb-2 w-fc all-caps">Kayak Chile</h6>
           <p>Camino Internacional 1330, Pucón, IX Región</p>
           <p><a class="text-black" href="tel:+56 9 5824 4974">+56 9 5824 4974</a> | <a class="text-black" href="tel:+56 9 6621 1541">+56 9 6621 1541</a></p>
           <p><a class="text-black" href="mailto:tiendakayakchile@gmail.com">tiendakayakchile@gmail.com</a></p>
       </div>
       <div class="col-6 col-md-3">
           <h4 v-if="$mq > 2" class="pb-2 border-bottom w-fc">{{aboutUs[worldLanguage]}}</h4>
           <h6 v-else class="py-2 border-bottom w-fc">{{aboutUs[worldLanguage]}}</h6>
           <router-link :to="{name: 'faq'}" class="text-black"><p @click="preventNavToggle">FAQ</p></router-link>
           <router-link :to="{name: 'our-team'}" class="text-black"><p @click="preventNavToggle">{{ourTeam[worldLanguage]}}</p></router-link>
           <router-link :to="{name: 'join-our-team'}" class="text-black"><p @click="preventNavToggle">{{joinOurTeam[worldLanguage]}}</p></router-link>
       </div>
       <div class="col-6 col-md-3">
           <h4 v-if="$mq > 2" class="pb-2 border-bottom border-danger w-fc">{{media[worldLanguage]}}</h4>
           <h6 v-else class="py-2 border-bottom border-danger w-fc">{{media[worldLanguage]}}</h6>
           <p><a class="text-black" href="https://www.facebook.com/kayakchilepucon/" target="_blank">facebook</a></p>
           <p><a class="text-black" href="https://www.instagram.com/kayakchile/" target="_blank">instagram</a></p>
           <p><a class="text-black" href="https://www.tripadvisor.com/Attraction_Review-g294297-d5174438-Reviews-Kayak_Chile-Pucón_Araucania_Region.html" target="_blank">tripadvisor</a></p>
       </div>
       <div :class="$mq > 2 ? 'text-right' : 'mt-2'" class="col-12">
           <p class="text-secondary text-small m-0">Built by <a href="https://www.github.com/porterwilcox" target="_blank" class="text-secondary">/porterwilcox</a></p>
       </div>
    </div>
</template>

<script>
export default {
   name: "footer-nav",
   props: [],
   data() {
      return {
          aboutUs: {
            en: "About Us",
            es: "Sobre Nosotros",
            de: "Über Uns",
            pt: "Sobre Nós"
         },
         media: {
            en: "Media",
            es: "Medios",
            de: "Medien",
            pt: "Mídia"
         },
         ourTeam: {
            en: "Our Team",
            es: "Nuestro Equipo",
            de: "Unser Team",
            pt: "Nosso Time"
         },
         joinOurTeam: {
            en: "Join Our Team",
            es: "Unete A Nuestro Equipo",
            de: "Trete Unserem Team Bei",
            pt: "Junte-se A Nossa Equipe"
         },
      }
   },
   computed: {
       worldLanguage() { return this.$store.state.worldLanguage }
   },
   methods: {
      preventNavToggle() { this.$store.commit('preventNavToggle') }
   },
   components: {}
}
</script>

<style>
.footer-nav p {
   margin: 0;
   padding: 0;
}
</style>