<template>
    <div class="book-your-trip w-100">
       <div v-if="isHome" class="row bg-dark2 h-10">
            <div class="col-12">
               <div class="d-flex w-50 justify-content-between align-items-center">
                  <h2 class="text-white all-caps mt-2 kc-border">Kayak Chile</h2>
                  <div v-if="$mq > 2" class="scroll-notify d-flex align-items-end text-white">
                     <p class="mt-3"><b>&#8595;</b></p>
                  </div>
               </div>
            </div>
      </div>
       <div :class="isHome ? 'h20' : 'h25'" class="row bg-dark2">
         <div class="col-8 col-md-6 d-flex justify-content-center flex-column">
            <slot>
                <!-- <h2 class="text-white">KAYAK CHILE</h2> -->
                <h4 class="text-white capitalized">{{subtitle[worldLanguage]}}</h4>
            </slot>
         </div>
         <div :class="isHome ? 'align-items-start mt-2' : 'align-items-center'" class="col-4 col-md-6 d-flex justify-content-center">
            <button :class="$mq <= 2 ? '' : 'btn-lg'" class="btn btn-outline-primary all-caps" data-toggle="modal" data-target=".contact-modal">{{bookBtn[worldLanguage]}}</button>
         </div>
      </div>
      <contact-modal />
    </div>
</template>

<script>
import ContactModal from '@/components/ContactModal.vue'

export default {
   name: "book-your-trip",
   props: [],
   data() {
      return {
          subtitle: {
            en: "Your Whitewater Experience",
            es: "su experiencia en aguas bravas",
            de: "Ihr Wildwassererlebnis",
            pt: "sua experiência com a água branca"
         },
          bookBtn: {
            en: "book your trip",
            es: "reserva tu viaje",
            de: "Buchen Sie Ihre Reise",
            pt: "marque sua viagem"
         }
      }
   },
   computed: {
       worldLanguage() { return this.$store.state.worldLanguage },
       isHome() { return this.$route.name == 'home' }
   },
   methods: { },
   components: { ContactModal }
}
</script>

<style>
.scroll-notify {
  animation: scroll-notify 2.5s infinite;
  z-index: 999999;
}

@keyframes scroll-notify {
  0%, 21%, 50%, 80%, 100% {
    opacity: 1;
    transform: translateY(0);
  }
  40% {
     opacity: .4;
    transform: translateY(-30px);
  }
  60% {
    opacity: .75;
    transform: translateY(-15px);
  }
}
</style>