<template>
    <div class="offering row">
        <div class="col-12 my-2">
            <h1 :class="$mq < 3 ? 'mt-3' : ''" class="pb-2 capitalized w-fc border-bottom text-kc-blue">{{offeringTitleData.title}} <br /> <select @change="routeToOtherOption" class="ml-1 mt-1 bg-none border-none select-style text-small opac-90 text-kc-blue" v-if="offeringOption">
                    <option :value="opt.linkTo" class="text-smaller" :key="opt.title" v-for="opt in offeringTitleData.options" :selected="opt.title == offeringOptionTitleData.title">{{opt.title}} <span v-if="$mq <= 2 && offeringOptionTitleData.title == opt.title">▽</span></option>
                    <option class="text-smaller" v-if="offeringName == 'multiday' && worldLanguage != 'en'" value="view-all">View all trips in English</option>
                </select></h1>
            <h5 v-if="!offeringOption" class="pt-2 text-danger text-center">{{offeringTitleData.subtitle}}</h5>
            <h5 v-else class="pt-2 text-danger text-center">{{offeringOptionTitleData.subtitle}}</h5>
        </div>
        <hr class="w-90">
        <div class="col-12">
            <rentals v-if="offeringName == 'rentals'" />
            <div v-else-if="offeringName == 'advanced-classes'">
                <div v-if="bensHere" class="caro-text-container slide-bottom position-absolute w-100 d-flex justify-content-center align-items-end" :class="$mq <=2 ? 'h-50' : 'h-75'">
                    <h3 class="caro-text ts-black">{{{en: 'Contact us to set up your custom itinerary!', pt: 'Entre em contato para criar seu itinerário personalizado!', de: 'Kontaktiere uns heute!', es: 'Contáctenos para crear su itinerario personalizado!'}[this.worldLanguage]}}</h3>
                </div>
                <img :src="SendyBen" style="zIndex: -1;" class="card-img-top mb-3 ben" />
                <book-your-trip>
                    <h6 :class="$mq < 3 ? 'text-small' : ''" v-text="{ pt: 'Nos conte um pouco de suas habilidades e o que você procura melhorar! Vai nos ajudar a construir uma classe especialmente para você!', en: 'Tell us a bit about your capabilities and what you are looking forward to improving! It will help us to develop a class specifically for you! Alternatively, one of our guides can show you the lines on any of the rivers in the area.', de: 'Erzähle uns doch ein bisschen etwas über deine Fähigkeiten und sage uns, was du gerne verbessern möchtest! Das hilft uns, um den Kajak-Unterricht speziell für dich anzupassen!', es: '¡Cuéntanos un poco acerca de tus capacidades y lo que estás buscando para mejorar! ¡Nos ayudará a desarrollar una clase específicamente para ti!'}[worldLanguage]" class="text-white text-center pl-2 custom-left"></h6>
                </book-your-trip>
            </div>
            <generic-offering-bulk-template v-else class="w-100" />
        </div>
    </div>
</template>

<script>
import Rentals from '@/components/Rentals.vue'
import GenericOfferingBulkTemplate from '@/components/GenericOfferingBulkTemplate.vue'
import BookYourTrip from '@/components/BookYourTrip.vue'
import SendyBen from '@/assets/sendy-ben.jpg'

export default {
   name: "offering",
   mounted(){
       window.scrollTo(0,0)
       if (this.offeringName == 'advanced-classes') {
           document.querySelector('img.ben').addEventListener('load', () => { this.bensHere = true })
       }
   },
   updated(){
       window.scrollTo(0,0)
   },
   props: [],
   data() {
      return { SendyBen, bensHere: false }
   },
   computed: {
       worldLanguage() { return this.$store.state.worldLanguage },
       offeringName() { return this.$route.params.offeringName },
       offeringOption() { return this.$route.params.offeringOption },
       offeringTitleData() {
           return this.$store.state.offeringsDict[this.worldLanguage].find(o => o.linkTo === this.offeringName)
       },
       offeringOptionTitleData() {
           return this.offeringTitleData.options.find(opt => opt.linkTo == this.offeringOption)
       }
   },
   methods: {
       routeToOtherOption(e) {
           let offeringOption = e.target.value
           if(offeringOption == 'view-all') {
               this.$store.commit('worldLanguage', 'en')
               offeringOption = '10-days-futaleufu'
           }
           this.$store.commit('preventNavToggle')
           this.$router.push({name: 'offering', params: {offeringName: this.offeringName, offeringOption}})
       }
   },
   components: { Rentals, GenericOfferingBulkTemplate, BookYourTrip }
}
</script>

<style>
p b {
    font-weight: 900;
}
.select-style, .select-style:active, .select-style:focus {
    outline: none;
}
</style>