<template>
    <div class="faq">
        <h1 :class="$mq < 3 ? 'mt-3' : ''" class="pb-2 my-2 capitalized w-fc border-bottom text-kc-blue">Frequently Asked Questions</h1>
        <hr class="w-100">
        <h5>Do You Sell Kayaks?</h5>
        <p>Unfortunately we do not sell kayaks. However, if you don’t have any experience with whitewater kayaks specifically, we highly suggest you take a lesson before you buy a kayak, seeing as they can be rather expensive. We offer <router-link class="text-black" :to="{name: 'offering', params: {offeringName: 'kayak-school'}}">one day kayak lessons</router-link> which will give you some basic white water kayak skill and experience. If you do have experience and are looking for a boat, Rivers Lakes and Oceans, on the corner of Colo-Colo and O’Higgins sells new kayaks as well as river equipment.</p>
        <h5>Can I get a job at Kayak Chile as a Guide?</h5>
        <p>While we do not guarantee work to people who are traveling to Chile, if you arrive in Chile, feel free to stop by the shop (Camino Internacional 1330, Pucón) and if we have any positions available at that time, we can discuss possible employment. However, we do hire practicantes (Chilean equivalent of un-paid interns).</p>
        <p>See more details: <router-link class="text-black" :to="{name: 'join-our-team'}">Join our team</router-link></p>
        <h5>Do you buy kayaks?</h5>
        <p>We have a strict policy on buying used kayaks. We will not buy any broken or welded boats, regardless of how old, how few times they have been used, etc. If you have an older model such as a Jefe, Diesel, etc. and it is in relatively good shaped compared to its age, I will buy it for $300 if I am in need of more kayaks. If you have a newer model (Karma, Nomad, Recon, etc.) that is in good condition (around 4 months of use), I will buy it for $500-$600 if I am in need of more kayaks. Now, for the clincher, if you have a very new model (Toro, New Nomad, 9R, etc.) that is in good condition, I will buy it for $700 if I am in need of more kayaks. Kayak Chile will not buy a kayak for more than $700 seeing as we can purchase a brand new kayak for $800 from our suppliers.</p>
        <h5>Do you accept Credit Cards?</h5>
        <p>Due to the high foreign transaction fees with credit cards, we currently do not accept credit cards. For day trips we accept cash only, and if you are doing a multi-day kayak course we accept payment via PayPal.</p>
    </div>
</template>

<script>
export default {
   name: "faq",
   mounted(){ window.scrollTo(0,0) },
   props: [],
   data() {
      return {}
   },
   computed: {},
   methods: {},
   components: {}
}
</script>

<style>
.faq h5 {
    font-weight: bold;
}
.faq p {
    color: var(--secondary);
}
</style>